// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: rgb(17, 153, 232);
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: rgb(14, 138, 209);
  --ion-color-primary-tint: rgb(14, 149, 227);

  /** secondary **/
  --ion-color-secondary: #5DADE2;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #569ecf;
  --ion-color-secondary-tint: #59aadf;

  /** tertiary **/
  --ion-color-tertiary: #3664c9;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #2e57b0;
  --ion-color-tertiary-tint: #3464ca;

  /** success **/
  --ion-color-success: #2cb977;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #259863;
  --ion-color-success-tint: #31bb7b;

  /** warning **/
  --ion-color-warning: #F1C40F;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #c6a10b;
  --ion-color-warning-tint: #ecc011;

  /** danger **/
  --ion-color-danger: #E74C3C;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #c04133;
  --ion-color-danger-tint: #eb5140;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #808B96;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #6d757d;
  --ion-color-medium-tint: #858d96;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** news **/
  --ion-color-pinkLight: #f088d1e0;
  --ion-color-pinkLight-rgb: 56, 128, 255;
  --ion-color-pinkLight-contrast: #ffffff;
  --ion-color-pinkLight-contrast-rgb: 255, 255, 255;
  --ion-color-pinkLight-shade: #d079b6e0;
  --ion-color-pinkLight-tint: #f086d0e0;

  --ion-color-pink: #fd62cfe0;
  --ion-color-pink-rgb: 56, 128, 255;
  --ion-color-pink-contrast: #ffffff;
  --ion-color-pink-contrast-rgb: 255, 255, 255;
  --ion-color-pink-shade: #cd37a0f6;
  --ion-color-pink-tint: #ff4cd8e5;

  --ion-color-orangeLight: #F39C12;
  --ion-color-orangeLight-rgb: 56, 128, 255;
  --ion-color-orangeLight-contrast: #ffffff;
  --ion-color-orangeLight-contrast-rgb: 255, 255, 255;
  --ion-color-orangeLight-shade: #e0a331;
  --ion-color-orangeLight-tint: #ffb14c;

  --ion-color-orange: #E67E22;
  --ion-color-orange-rgb: 56, 128, 255;
  --ion-color-orange-contrast: #ffffff;
  --ion-color-orange-contrast-rgb: 255, 255, 255;
  --ion-color-orange-shade: #d27422;
  --ion-color-orange-tint: #e97f23;

  --ion-color-greenLight: #48C9B0;
  --ion-color-greenLight-rgb: 235, 68, 90;
  --ion-color-greenLight-contrast: #ffffff;
  --ion-color-greenLight-contrast-rgb: 255, 255, 255;
  --ion-color-greenLight-shade: #53bb82;
  --ion-color-greenLight-tint: #56c57b;

  --ion-color-green: #16A085;
  --ion-color-green-rgb: 56, 128, 255;
  --ion-color-green-contrast: #ffffff;
  --ion-color-green-contrast-rgb: 255, 255, 255;
  --ion-color-green-shade: #52baa5;
  --ion-color-green-tint: #56dfc4;

  --ion-color-blueLight: #5DADE2;
  --ion-color-blueLight-rgb: 56, 128, 255;
  --ion-color-blueLight-contrast: #ffffff;
  --ion-color-blueLight-contrast-rgb: 255, 255, 255;
  --ion-color-blueLight-shade: #4887b1;
  --ion-color-blueLight-tint: #5cace1;

  --ion-color-blue: #0e9ab3;
  --ion-color-blue-rgb: 56, 128, 255;
  --ion-color-blue-contrast: #ffffff;
  --ion-color-blue-contrast-rgb: 255, 255, 255;
  --ion-color-blue-shade: #0c7a8d;
  --ion-color-blue-tint: #0f9cb5;

  --ion-color-blueDark: #2E86C1;
  --ion-color-blueDark-rgb: 56, 128, 255;
  --ion-color-blueDark-contrast: #ffffff;
  --ion-color-blueDark-contrast-rgb: 255, 255, 255;
  --ion-color-blueDark-shade: #286f9e;
  --ion-color-blueDark-tint: #2e88c3;

  --ion-color-violetLight: #9B59B6;
  --ion-color-violetLight-rgb: 255, 73, 97;
  --ion-color-violetLight-contrast: #ffffff;
  --ion-color-violetLight-contrast-rgb: 255, 255, 255;
  --ion-color-violetLight-shade: #7c4890;
  --ion-color-violetLight-tint: #9b57b6;

  --ion-color-violet: #7D3C98;
  --ion-color-violet-rgb: 255, 73, 97;
  --ion-color-violet-contrast: #ffffff;
  --ion-color-violet-contrast-rgb: 255, 255, 255;
  --ion-color-violet-shade: #5e2e73;
  --ion-color-violet-tint: #7c3d96;

  --ion-color-coffe: #BA4A00;
  --ion-color-coffe-rgb: 255, 73, 97;
  --ion-color-coffe-contrast: #ffffff;
  --ion-color-coffe-contrast-rgb: 255, 255, 255;
  --ion-color-coffe-shade: #973e02;
  --ion-color-coffe-tint: #bd4d02;

  --ion-color-yellow: #F1C40F;
  --ion-color-yellow-rgb: 255, 73, 97;
  --ion-color-yellow-contrast: #ffffff;
  --ion-color-yellow-contrast-rgb: 255, 255, 255;
  --ion-color-yellow-shade: #cba610;
  --ion-color-yellow-tint: #edc10f;

  --ion-color-coral: #F08080;
  --ion-color-coral-rgb: 255, 73, 97;
  --ion-color-coral-contrast: #ffffff;
  --ion-color-coral-contrast-rgb: 255, 255, 255;
  --ion-color-coral-shade: #cc6e6e;
  --ion-color-coral-tint: #f48383;

  --ion-color-indianRed: #CD5C5C;
  --ion-color-indianRed-rgb: 255, 73, 97;
  --ion-color-indianRed-contrast: #ffffff;
  --ion-color-indianRed-contrast-rgb: 255, 255, 255;
  --ion-color-indianRed-shade: #a34c4c;
  --ion-color-indianRed-tint: #c75a5a;
}

body[color-theme="dark"] {
  /** primary **/
  --ion-color-primary: rgb(17, 153, 232);
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: rgb(14, 138, 209);
  --ion-color-primary-tint: rgb(14, 149, 227);

  /** secondary **/
  --ion-color-secondary: #5DADE2;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #569ecf;
  --ion-color-secondary-tint: #59aadf;

  /** tertiary **/
  --ion-color-tertiary: #3664c9;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #2e57b0;
  --ion-color-tertiary-tint: #3464ca;

  /** success **/
  --ion-color-success: #2cb977;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #259863;
  --ion-color-success-tint: #31bb7b;

  /** warning **/
  --ion-color-warning: #F1C40F;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #c6a10b;
  --ion-color-warning-tint: #ecc011;

  /** danger **/
  --ion-color-danger: #E74C3C;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #c04133;
  --ion-color-danger-tint: #eb5140;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #808B96;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #6d757d;
  --ion-color-medium-tint: #858d96;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** news **/
  --ion-color-pinkLight: #f088d1e0;
  --ion-color-pinkLight-rgb: 56, 128, 255;
  --ion-color-pinkLight-contrast: #ffffff;
  --ion-color-pinkLight-contrast-rgb: 255, 255, 255;
  --ion-color-pinkLight-shade: #d079b6e0;
  --ion-color-pinkLight-tint: #f086d0e0;

  --ion-color-pink: #fd62cfe0;
  --ion-color-pink-rgb: 56, 128, 255;
  --ion-color-pink-contrast: #ffffff;
  --ion-color-pink-contrast-rgb: 255, 255, 255;
  --ion-color-pink-shade: #cd37a0f6;
  --ion-color-pink-tint: #ff4cd8e5;

  --ion-color-orangeLight: #F39C12;
  --ion-color-orangeLight-rgb: 56, 128, 255;
  --ion-color-orangeLight-contrast: #ffffff;
  --ion-color-orangeLight-contrast-rgb: 255, 255, 255;
  --ion-color-orangeLight-shade: #e0a331;
  --ion-color-orangeLight-tint: #ffb14c;

  --ion-color-orange: #E67E22;
  --ion-color-orange-rgb: 56, 128, 255;
  --ion-color-orange-contrast: #ffffff;
  --ion-color-orange-contrast-rgb: 255, 255, 255;
  --ion-color-orange-shade: #d27422;
  --ion-color-orange-tint: #e97f23;

  --ion-color-greenLight: #48C9B0;
  --ion-color-greenLight-rgb: 235, 68, 90;
  --ion-color-greenLight-contrast: #ffffff;
  --ion-color-greenLight-contrast-rgb: 255, 255, 255;
  --ion-color-greenLight-shade: #53bb82;
  --ion-color-greenLight-tint: #56c57b;

  --ion-color-green: #16A085;
  --ion-color-green-rgb: 56, 128, 255;
  --ion-color-green-contrast: #ffffff;
  --ion-color-green-contrast-rgb: 255, 255, 255;
  --ion-color-green-shade: #52baa5;
  --ion-color-green-tint: #56dfc4;

  --ion-color-blueLight: #5DADE2;
  --ion-color-blueLight-rgb: 56, 128, 255;
  --ion-color-blueLight-contrast: #ffffff;
  --ion-color-blueLight-contrast-rgb: 255, 255, 255;
  --ion-color-blueLight-shade: #4887b1;
  --ion-color-blueLight-tint: #5cace1;

  --ion-color-blue: #0e9ab3;
  --ion-color-blue-rgb: 56, 128, 255;
  --ion-color-blue-contrast: #ffffff;
  --ion-color-blue-contrast-rgb: 255, 255, 255;
  --ion-color-blue-shade: #0c7a8d;
  --ion-color-blue-tint: #0f9cb5;

  --ion-color-blueDark: #2E86C1;
  --ion-color-blueDark-rgb: 56, 128, 255;
  --ion-color-blueDark-contrast: #ffffff;
  --ion-color-blueDark-contrast-rgb: 255, 255, 255;
  --ion-color-blueDark-shade: #286f9e;
  --ion-color-blueDark-tint: #2e88c3;

  --ion-color-violetLight: #9B59B6;
  --ion-color-violetLight-rgb: 255, 73, 97;
  --ion-color-violetLight-contrast: #ffffff;
  --ion-color-violetLight-contrast-rgb: 255, 255, 255;
  --ion-color-violetLight-shade: #7c4890;
  --ion-color-violetLight-tint: #9b57b6;

  --ion-color-violet: #7D3C98;
  --ion-color-violet-rgb: 255, 73, 97;
  --ion-color-violet-contrast: #ffffff;
  --ion-color-violet-contrast-rgb: 255, 255, 255;
  --ion-color-violet-shade: #5e2e73;
  --ion-color-violet-tint: #7c3d96;

  --ion-color-coffe: #BA4A00;
  --ion-color-coffe-rgb: 255, 73, 97;
  --ion-color-coffe-contrast: #ffffff;
  --ion-color-coffe-contrast-rgb: 255, 255, 255;
  --ion-color-coffe-shade: #973e02;
  --ion-color-coffe-tint: #bd4d02;

  --ion-color-yellow: #F1C40F;
  --ion-color-yellow-rgb: 255, 73, 97;
  --ion-color-yellow-contrast: #ffffff;
  --ion-color-yellow-contrast-rgb: 255, 255, 255;
  --ion-color-yellow-shade: #cba610;
  --ion-color-yellow-tint: #edc10f;

  --ion-color-coral: #F08080;
  --ion-color-coral-rgb: 255, 73, 97;
  --ion-color-coral-contrast: #ffffff;
  --ion-color-coral-contrast-rgb: 255, 255, 255;
  --ion-color-coral-shade: #cc6e6e;
  --ion-color-coral-tint: #f48383;

  --ion-color-indianRed: #CD5C5C;
  --ion-color-indianRed-rgb: 255, 73, 97;
  --ion-color-indianRed-contrast: #ffffff;
  --ion-color-indianRed-contrast-rgb: 255, 255, 255;
  --ion-color-indianRed-shade: #a34c4c;
  --ion-color-indianRed-tint: #c75a5a;
}

.ios body[color-theme="dark"] {
  --ion-background-color: #1e1e1e;
  --ion-background-color-rgb: 0, 0, 0;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-border-color: #222222;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-item-background: #171717;

  --ion-card-background: #171717;
}

.ios ion-modal {
  --ion-background-color: var(--ion-color-step-100);
  --ion-toolbar-background: var(--ion-color-step-150);
  --ion-toolbar-border-color: var(--ion-color-step-250);
}

.md body[color-theme="dark"] {
  --ion-background-color: #1e1e1e;

  --ion-background-color-rgb: 18, 18, 18;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-border-color: #222222;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-item-background: #171717;

  --ion-toolbar-background: #1f1f1f;

  --ion-tab-bar-background: #1f1f1f;

  --ion-card-background: #171717;
}
